<div *ngIf="room">
    <h2 class="room-name">{{room.name}}</h2>
    <h2 *ngIf="room.text" class="room-text">
        <span *ngFor="let line of room.text.split('\n')">{{line}}<br/></span>
    </h2>
    <div *ngIf="hasThumbnails" class="row">
        <div class="column">
            <span *ngFor="let page of room.pages">
                <a routerLink="/page/{{this.catalogue.getPageId(page)}}"
                routerLinkActive="active">
                    <img src="{{thumbnails.get(page)}}" class="thumbnail"/>
                </a>
            </span>
        </div>
    </div>
    <div *ngIf="!hasThumbnails">
        <ul>
            <li *ngFor="let page of room.pages">
                <a routerLink="/page/{{this.catalogue.getPageId(page)}}"
                routerLinkActive="active">
                    <span *ngIf="page.title" class="page-title">{{page.title}}</span>
                    <p *ngIf="page.text" class="page-text">
                        <span *ngFor="let line of page.text.split('\n')">{{line}}<br/></span>
                    </p>
                    <ul>
                        <li *ngFor="let image of page.images">
                            <img src="{{catalogue.getImageLink(image)}}"/>
                        </li>
                    </ul>
                </a>
            </li>
        </ul>
    </div>
</div>

import { Injectable } from '@angular/core';
import { GalleryRoom } from './gallery-room';
import { GalleryPage } from './gallery-page';
import { GalleryImage } from './gallery-image';

// 'assets/';
const JMCAROLINO_BASE_URL = "https://jmcarolino.pt/";

const CATALOGUE: GalleryRoom[] = [
  {name: "Prelúdio",
  folder: "fotos/1_preludio",
  pages: [
    {title: "Prelúdio",
    images: [
      {image: "1_preludio_jose_mario_carolino.png"}],
    thumbnail: "page-001.png"},
    {title: "Lisboa",
    images: [
      {image: "2_preludio_jose_mario_carolino_bebe1.jpg"},
      {image: "2_preludio_jose_mario_carolino_bebe.jpg"},
      {image: "3_preludio_jose_mario_carolino_basquete.png"},
    ],
    text: `José Amândio e Anabela
Irmãos
Primeira namorada
Crescer na rua
Partir vidros e fugir
Basquetebol
Carros de esferas`,
    thumbnail: "page-002.png"},
    {title: "Coimbra",
    images: [
      {image: "4_preludio_jose_mario_carolino_cartola.png"},
      {image: "5_preludio_jose_mario_carolino_orxestra_pitagorica.png"},
    ],
    text: `Orxestra Pitagórica
Boémia
Cerveja
Amigos
Curso
Eterna`,
    thumbnail: "page-003.png"},
    {title: "Algarve",
    images: [
      {image: "6_preludio_jose_mario_carolino_orxestra_farmacia.png"},
      {image: "7_preludio_jose_mario_carolino_moura_encantada.png",
      workDetails: "foto: Herberto"},
    ],
    text: `Farmácia
José Miguel
Pintura
Teatro
Mais amigos`,
    thumbnail: "page-004.png"},
    {title: "Trabalho",
    images: [
      {image: "8_preludio_sem_titulo_1995.png",
      title: "Sem título",
      year: "1995",
      workDetails: "1000 x 600 mm, Óleo/Técnica Mista"},
      {image: "9_preludio_sem_titulo_1996.png",
      title: "Sem título",
      year: "1996",
      workDetails: "1620 x 970 mm, Óleo/Técnica Mista"},
      {image: "10_preludio_tripe.png"},
      {image: "11_preludio_ria.png"},
      {image: "12_preludio_telas_secar.png"},
      {image: "13_preludio_jose_mario_carolino_trabalhar.png"},
      {image: "14_preludio_estudio.png"},
      {image: "15_preludio_telas_cortadas.png"},
      {image: "16_preludio_jose_mario_carolino_trabalhar.png"},
      {image: "17_preludio_telas_cortadas.png"},
      {image: "18_preludio_jose_mario_carolino_trabalhar.png"},
      {image: "19_preludio_jose_mario_carolino_trabalhar.png"},
    ],
    thumbnail: "page-005.png"},
  ]},
  {name: "Construir, Destruir, Reconstruir",
  folder: "fotos/2_construir_destruir_reconstruir",
  roomImage: "fotos/imagens_titulos/construir_destruir_reconstruir.png",
  pages: [
    {text: `Há dias em que tudo corre mal; aborreces-te com a namorada, bebes uns copos a mais, e, quando chegas a casa, entras na garagem, deparas-te com quadros inacabados e um frigorifico com mais cerveja.
Os dados estão mal lançados; pegas num x-ato, começas a esquartejar telas e a entrelaça-las.
Não tem lógica, porque nem as telas têm culpa, nem uma tela tem nada a ver com a outra. Acabam entrelaçadas e pregadas e vais dormir.
Pronto, já está, amanhã é outro dia e tudo se comporá!!
Acordo, e nem me lembro da tela (das telas) ...
... mas ao passar pela garagem, não só te lembras como olhas, olhas e reolhas e tentas perceber o que fizeste...
«Zé, não fizeste nada de especial!! Mas isto mexe contigo!»
Acho que tem movimento, têm uma lógica diferente, o espaço ganha qualquer coisa...
    
Durante 4 ou 5 anos, olhei imensas vezes para a tela, a tentar perceber porque me seduzia, mas nada fiz!
Um dia percebi que tinha o que ela me dizia. Tinha um caos e tinha que acrescentar um caos diferente... diversidade, similitudes, assimetrias, erros...
Aquela tela tinha de viver, fazer viver, ser realista e ir para além do real.`,
    images: [
      {image: "20_construir_destruir_reconstruir_acidente_2005.png",
      title: "Acidente em 2005",
      workDetails: "650 x 1000 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-006.png"},
    {images: [
      {image: "21_construir_destruir_reconstruir_primus_inter_paris_2010.png",
      title: "Primus Inter Pares",
      year: "2010",
      workDetails: "600 x 700 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-007.png"},
    {images: [
      {image: "22_construir_destruir_reconstruir_fonte_de_luz_2010.JPG",
      title: "Fonte de Luz",
      year: "2010",
      workDetails: "800 x 650 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-008.png"},
    {images: [
      {image: "23_construir_destruir_reconstruir_crying_2010.png",
      title: "Crying",
      year: "2010",
      workDetails: "1000 x 500 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-009.png"},
    {images: [
      {image: "24_construir_destruir_reconstruir_dois_homens_e_uma_lampada_2010.png",
      title: "Dois Homens e Uma Lâmpada",
      year: "2010",
      workDetails: "1050 x 560 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-010.png"},
    {images: [
      {image: "25_construir_destruir_reconstruir_fruteira_2011.png",
      title: "Fruteira",
      year: "2011",
      workDetails: "600 x 600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-011.png"},
    {images: [
      {image: "26_construir_destruir_reconstruir_grey_and_rain_2011.png",
      title: "Grey and Rain",
      subTitle: "[G. R./ O. G.]",
      year: "2011",
      workDetails: "1200 x 800 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-012.png"},
    {images: [
      {image: "27_construir_destruir_reconstruir_its_raining_again_2012.png",
      title: "It´s Raining Again",
      year: "2012",
      workDetails: "2400 x 600 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-013.png"},
    {images: [
      {image: "28_construir_destruir_reconstruir_xilophorus_h__2012.png",
      title: "Xiphophorus H.",
      year: "2012",
      workDetails: "1000 mm x 1000 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-014.png"},
    {images: [
      {image: "29_construir_destruir_reconstruir_the_clown_2012.JPG",
      title: "The Clown",
      year: "2012",
      workDetails: "600 x 800 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-015.png"},
    {images: [
      {image: "30_construir_destruir_reconstruir_trap_2012.png",
      title: "Trap.",
      year: "2012",
      workDetails: "1400 mm x 700 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-016.png"},
    {images: [
      {image: "31_construir_destruir_reconstruir_hard_sex_2012.JPG",
      title: "Hard Sex",
      year: "2012",
      workDetails: "500 mm x 1000 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-017.png"},
    {images: [
      {image: "33_construir_destruir_reconstruir_saudades_2012.png",
      title: "Saudades",
      year: "2012",
      workDetails: "1500 mm x 500 mm, Óleo/Técnica Mista (Col. Privada)"},
      {image: "34_construir_destruir_reconstruir_gallery_weekend_berlin.png"}
    ],
    thumbnail: "page-018.png"},
    {images: [],
    textAuthor: "Vítor Cardeira",
    text: `Agora,
o tempo traz de novo,
repete,
o que nunca aconteceu.`,
    thumbnail: "page-019.png"},
    {images: [
      {image: "35_construir_destruir_reconstruir_sol_2012.png",
      title: "Sol",
      year: "2012",
      workDetails: "1600 x 1600 mm, Óleo/Técnica Mista"},
      {image: "36_construir_destruir_reconstruir_sol_2012_luisa_pedro.png"}
    ],
    thumbnail: "page-020.png"},
    {images: [
      {image: "37_construir_destruir_reconstruir_la_luna_2012.png",
      title: "La Luna",
      year: "2012",
      workDetails: "1200 x 1200 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-021.png"},
    {images: [
      {image: "38_construir_destruir_reconstruir_blue_sky_2012.png",
      title: "Blue Sky",
      year: "2012",
      workDetails: "1400 mm x 700 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-022.png"},
    {images: [
      {image: "39_construir_destruir_reconstruir_a_mascara_2013.png",
      title: "A Máscara",
      year: "2013",
      workDetails: "500 x 700 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-023.png"},
    {images: [
      {image: "40_construir_destruir_reconstruir_black_bird_2013.png",
      title: "Black Bird",
      year: "2013",
      workDetails: "1000 x 1000 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-024.png"},
    {images: [
      {image: "41_construir_destruir_reconstruir_berlin_2013.png",
      title: "Berlim",
      year: "2013",
      workDetails: "2000 x 1000 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-025.png"},
    {images: [],
    text: `Um dia olhei e vi, construir, destruir, reconstruir, vi um caos organizado; isto é a natureza, isto é o ser humano!
Quis movimento e alternância porque é assim a vida!

As faixas não deveriam ser idênticas em tamanho, nem ser rectas perfeitas. Tal como a natureza e o homem são diversos, o quadro devia reflecti-lo.
Os entrelaçados não deveriam seguir uma regra constante. As relações humanas são diversas e é isso que nos faz sentir; o quadro devia reflecti-lo.
As cores devem ter alternâncias, como têm movimento o sol, as pessoas, e perspectivas; o quadro devia reflecti-lo.
E o erro? O erro deve ser assumido. Só deve ser corrigido quando põe em causa uma relação. 
Também a pincelada deve oscilar entre a mais pensada e delicada e a mais ocasional e bruta; é assim que eu sou, é assim que os meus trabalhos devem ser e o quadros também.
Os pontos (coseduras); uns mais pequenos, quase só estruturais, uns mais longos, mais emocionais. Muitos ou poucos, tal como os nossos sentires, as nossas vontades e nossos desejos; com ou sem cor... o meu quadro tem de refleti-lo.

Muitas vezes me questiono, «Isto é arte?», mas também me sucede, passados mais de 20 anos, acordar e ter a sensação que me faltam cadeiras para terminar a licenciatura.

Eu construo-me, destruo-me, reconstruo-me, todos os dias!`,
    thumbnail: "page-026.png"},
    {images: [
      {image: "42_construir_destruir_reconstruir_burning_desire_2013.png",
      title: "Burning Desire",
      year: "2013",
      workDetails: "800 x 1200 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-027.png"},
    {images: [
        {image: "43_construir_destruir_reconstruir_eclipse_2013.png",
        title: "Eclipse",
        year: "2013",
        workDetails: "800 x 1000 mm, Óleo/Técnica Mista"}
      ],
      thumbnail: "page-028.png"},
    ]},
  {name: "Live, Love & Laugh",
  folder: "fotos/3_live_love_and_laugh",
  roomImage: "fotos/imagens_titulos/live_love_and_laugh.png",
  text: `Para ti, por ti, para sempre...
Jota`,
  pages: [
    {images: [
      {image: "43_live_love_and_laugh_entre_labios_e_labios.JPG",
      title: "“Entre Lábios e Lábios...”",
      year: "2015",
      workDetails: "700 x 700 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-01-entre-labios-e-labios.png"},
    {images: [
      {image: "44_live_love_and_laugh_blue_and_blue.png",
      title: "Blue and Blue",
      year: "2014",
      workDetails: "2 x 800 x 800 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-02-blue-and-blue.png"},
    {images: [
      {image: "45_live_love_and_laugh_live_love_and_laugh_2014.png",
      title: "Live, Love & Laugh",
      year: "2014",
      workDetails: "2000 x 910 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-03-live-love-and-laugh.png"},
    {text: "Procura os que te arrastam até ao fim das sombras do desejo.",
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "46_live_love_and_laugh_justine_2014.png",
      title: "Justine",
      year: "2014",
      workDetails: "1000 x 800 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-04-justine.png"},
    {images: [
      {image: "47_live_love_and_laugh_penis_erectus_2014.png",
      title: "Penis Erectus",
      year: "2014",
      workDetails: "800 x 2200 mm, Óleo/Técnica Mista"},
      {image: "48_live_love_and_laugh_penis_erectus_2014_costas.png"}
    ],
    thumbnail: "page-3-05-penis-erectus.png"},
    {images: [
      {image: "49_live_love_and_laugh_endorfinas_2013.png",
      title: "Endorfinas",
      year: "2013",
      workDetails: "1000 x 1200 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-06-endorfinas.png"},
    {text: `Depois de ti
virá o que
quero esquecer.`,
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "50_live_love_and_laugh_fast_life_2015.png",
      title: "Fast Life",
      year: "2015",
      workDetails: "900 x 900 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-3-07-fast-life.png"},
  ]},
  {name: "Silêncios",
  folder: "fotos/4_silencios",
  roomImage: "fotos/imagens_titulos/SILENCIOS.png",
  pages: [
    {text: "Pesadelos que se impõem como forma de vida.",
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "51_silencios_silencios_2018.png",
      title: "Silêncios",
      year: "2018",
      workDetails: "2800 x 1000 mm, Óleo/Técnica Mista"},
    ],
    thumbnail: "page-4-01-silencios.png"},
    {images: [
      {image: "52_silencios_pro_infinito_e_mais_2017_19.png",
      title: "Pró Infinito e Mais!",
      year: "2017-2019",
      workDetails: "1800 x 1800 mm, Óleo/Técnica Mista"},
      {image: "54_silencios_ze_maria_joao.png"}
    ],
    thumbnail: "page-4-02-pro-infinito-e-mais.png"},
    {images: [
      {image: "53_silencios_pormenor_cosimento.png"},
      {image: "53_silencios_miopia_distanciamento_2017.png",
      title: "Miopia e Distanciamento",
      year: "2017",
      workDetails: "800 x 600 mm, Óleo/Técnica Mista (Col. Privada)"},
    ],
    thumbnail: "page-4-03-miopia-e-distanciamento.png"},
    {images: [
      {image: "55_silencios_tranquilidades_e_verticalidade_2017_19.png",
      title: "Tranquilidade e Verticalidade",
      year: "2017-2019",
      workDetails: "2400 x 1600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-04-tranquilidade-e-verticalidade.png"},
    {images: [
      {image: "56_silencios_sequencia_trabalho.png"},
      {image: "57_silencios_ze_pai.png",
      title: "Ao meu Pai"},
    ],
    thumbnail: "page-4-05-ao-meu-pai.png"},
    {images: [
      {image: "58_silencios_tranquilidades_e_verticalidade_2017_19_costas.png",
      title: "Tranquilidade e Verticalidade",
      subTitle: "Pormenor das costas",
      year: "2017-2019",
      workDetails: "2400 x 1600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-06-tranquilidade-e-verticalidade-pormenor-das-costas.png"},
    {images: [
      {image: "59_silencios_tranquilidades_e_verticalidade_2017_19_pormenor.png",
      title: "Tranquilidade e Verticalidade",
      subTitle: "Pormenor",
      year: "2017-2019",
      workDetails: "2400 x 1600 mm, Óleo/Técnica Mista"},
      {image: "60_silencios_ana_luisa.png"}
    ],
    thumbnail: "page-4-07-tranquilidade-e-verticalidade-pormenor.png"},
    {images: [
      {image: "61_silencios_arvores_viajantes_2015.png",
      title: "Árvores Viajantes",
      year: "2015",
      workDetails: "1400 x 1200 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-08-arvores-viajantes.png"},
    {images: [
      {image: "62_silencios_o_meu_canto_2016.png",
      title: "O Meu Canto",
      year: "2016",
      workDetails: "900 x 900 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-09-o-meu-canto.png"},
    {images: [
      {image: "63_silencios_floresta_k_2017.png",
      title: "Floresta K",
      year: "2017",
      workDetails: "1400 x 600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-10-floresta-k.png"},
    {images: [],
    text: `A Paixão na Ausência

O silêncio acompanha-me os passos. 
Procuro-vos por toda a parte e a ausência afasta-me de vós. 
Os gritos arrancam-me ao passado que nos uniu, 
as memórias são agora o que me resta: o vazio traz de volta o nada, 
a ferida aberta, que existiu antes de os conhecer. Antes de nós. 
Não posso mais caminhar por onde os nossos pés 
tatuaram a paisagem impoluta. 
Nem regressar às sombras por onde navegam, agora, os vossos corpos. 
Longe das minhas mãos carentes, um rio sem margens inunda a minha vida.

No fim de tudo, a ausência permite a eternidade da paixão.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-4-11-txt-a-paixao-na-ausencia.png"},
    {images: [
      {image: "64_silencios_ria_formosa_2017_2018.png",
      title: "Ria Formosa",
      year: "2017-2018",
      workDetails: "2400 x 600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-4-12-ria-formosa.png"},
    {images: [
      {image: "65_silencios_ria_formosa_2017_2018_pormenores.png"},
      {image: "66_silencios_ria_formosa_ze.png"},
    ],
    thumbnail: "page-4-13-ria-formosa-pormenores-ze.png"},
  ] },
  {name: "Caixas, Vazios e Nadas",
  folder: "fotos/5_caixas_vazios_nadas",
  roomImage: "fotos/imagens_titulos/CAIXAS_VAZIOS_NADAS.png",
  pages: [
    {text: `Nas casas em construção,
o sofrimento foi
a primeira pedra.`,
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "67_caixas_vazios_nadas_ze_nu.png"},
      {image: "68_caixas_vazios_nadas_vou_por_aqui_e_depois_2018.png",
      title: "Vou por aqui e depois...",
      year: "2018",
      workDetails: "1200 x 1800 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-01-vou-por-aqui-e-depois.png"},
    {images: [
      {image: "69_caixas_vazios_nadas_caixa_do_nada_2017.png",
      title: "Caixa do Nada",
      year: "2017",
      workDetails: "900 x 700 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-02-caixa-do-nada.png"},
    {images: [
      {image: "70_caixas_vazios_nadas_caixa_do_vazio_2018.png",
      title: "Caixa do Vazio",
      year: "2018",
      workDetails: "900 x 900 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-03-caixa-do-vazio.png"},
    {images: [],
    text: `Uma alma estrangeira, 
desejando o impossível das desassossegadas tentações,
ampliando as liberdades de quem quer o infinito.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-5-04-txt-uma-alma-estrangeira.png"},
    {images: [
      {image: "71_caixas_vazios_nadas_o_mundo_fez_se_grande_2018.png",
      title: "O Mundo Fez-se Grande",
      year: "2018",
      workDetails: "2400 x 1600 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-05-o-mundo-fez-se-grande.png"},
    {images: [
      {image: "72_caixas_vazios_nadas_truz_truz_quem_e_2019.png",
      title: "Truz, Truz... Quem é?",
      year: "2019",
      workDetails: "1200 x 2100 mm, Óleo/Técnica Mista"},
      {image: "73_caixas_vazios_nadas_truz_truz_quem_e_2019_pedro.png"}
    ],
    thumbnail: "page-5-06-truz-truz-quem-e.png"},
    {images: [
      {image: "74_caixas_vazios_nadas_entendi_te_me_2020.png",
      title: "Entendi-te-me",
      year: "2020",
      workDetails: "900 x 900 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-07-entendi-te-me.png"},
    {text: `Não deixes acabar 
a noite fria.`,
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "75_caixas_vazios_nadas_insanidade_2020.png",
      title: "Insanidade",
      year: "2020",
      workDetails: "800 x 1000 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-08-insanidade.png"},
    {images: [
      {image: "76_caixas_vazios_nadas_reequilibrios_2020.png",
      title: "Reequilíbrios",
      year: "2020",
      workDetails: "900 x 900 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-09-reequilibrios.png"},
      {images: [
      {image: "77_caixas_vazios_nadas_esquisso_de_luz_2020.png",
      title: "Esquisso",
      year: "2020",
      workDetails: "1500 x 800 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-10-esquisso.png"},
    {images: [],
    text: `- E depois dos incêndios?
    - Depois as nossas árvores ainda que sem vida permanecem em pé. Parecem caixas vazias, mas não! Apenas as arrumamos e estão cheias de tudo. Farto-me de rir quando olho para dentro delas; não me lembram nada de mau, só coisas fantásticas.
    
    - E quando caímos?
    - Caímos e levantamo-nos. Seguimos em frente, à procura de caminhos desconhecidos, batemos a novas portas, fechamos ou abrimos as nossas, saímos para a rua, precisamos de luz.
    
    - E os cinzentos?
    - Os cinzentos não são de tristeza, são de serenidade.
    
    - Gostas da frase do Fernando Pessoa «Querendo, quero o infinito»?
    - Adoro! É daquelas frases que me empolgam. Só me apetece abrir os braços e gritar.
    Há de haver sempre coisas novas para descobrir, paisagens incríveis, pessoas fabulosas, há que amar. O mundo fez-se grande e só temos de o aproveitar.
    
    - Gostas de ti?
    - Eu também!`,
    thumbnail: "page-5-13-txt-e-depois-dos-incendios.png"},
    {images: [
      {image: "78_caixas_vazios_nadas_sublimar_2020.jpg",
      title: "Sublimar",
      year: "2020",
      workDetails: "1400 x 900 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-5-11-sublimar.png"},
    {images: [
      {image: '82_fachada_2021.jpg',
      title: 'Fachada',
      year: '2021',
      workDetails: '1500 x 800 mm'}
    ],
    thumbnail: '82_fachada_2021_thumbnail.png'},
    {images: [
        {image: "80_caixas_vazios_nadas_amor_cheguei_.png",
        title: "Amor cheguei!",
        year: "2021",
        workDetails: "1600 x 1500 mm, Óleo/Técnica Mista"},
        {image: "80_caixas_vazios_nadas_amor_cheguei_perspetiva_recorte.png"}
      ],
      thumbnail: "page-5-13-amor-cheguei.png"},
  ]},
  {name: "Algures e Nenhures",
  folder: "fotos/10_algures_nenhures",
  //roomImage: "",
  pages: [
    {images: [
      {image: "79_a_14_de_maio_num_beco_qualquer_2020.jpg",
      title: "A 14 de Maio num beco qualquer",
      year: "2020",
      workDetails: "1400 x 900 mm, Óleo/Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "79_a_14_de_maio_num_beco_qualquer_2020_thumbnail.png"},
    {images: [
      {image: '80_diz_me_ja_2022.jpg',
      title: 'Diz-me! Já!',
      year: '2022',
      workDetails: '1000 x 1000 mm'}
    ],
    thumbnail: '80_dizme_ja_2022_thumbnail.png'},
    {images: [
      {image: '81_a_ultima_ceia_2021.jpg',
      title: 'A última ceia',
      year: '2021',
      workDetails: '1400 x 900 mm'}
    ],
    thumbnail: '81_a_ultima_ceia_2021_thumbnail.png'},
    {images: [
      {image: '82_a_inventar_o_dia_2023.jpg',
      title: 'A inventar o dia',
      year: '2023',
      workDetails: '1500 x 800 mm'}
    ],
    thumbnail: '82_a_inventar_o_dia_2023_thumbnail.png'},
    {images: [
      {image: '83_na_casa_da_minha_avo_2023.jpg',
      title: 'Na casa da minha avó',
      year: '2023',
      workDetails: '800 x 1500 mm'}
    ],
    thumbnail: '83_na_casa_da_minha_avo_2023_thumbnail.png'},
    {images: [
      {image: '84_epilogo_2022.jpg',
      title: 'Epílogo',
      year: '2022',
      workDetails: '1400 x 900 mm'}
    ],
    thumbnail: '84_epilogo_2022_thumbnail.png'},
  ]},
  {name: "Passos de Papel",
  folder: "fotos/11_passos_de_papel",
  pages: [
    {images: [
      {image: "01_luz_pela_madrugada_2024.jpg",
      title: "Luz pela madrugada",
      year: '2024',
      workDetails: '600 x 500 mm'}
    ],
    thumbnail: '01_luz_pela_madrugada_2024_thumbnail.png'},
    {images: [
      {image: '02_luz_pela_noite_2024.jpg',
      title: 'Luz pela noite',
      year: '2024',
      workDetails: '600 x 400 mm'}
    ],
    thumbnail: '02_luz_pela_noite_2024_thumbnail.png'},
    {images: [
      {image: '03_penedo_da_saudade_2024.jpg',
      title: 'Penedo da saudade',
      year: '2024',
      workDetails: '900 x 600 mm'}
    ],
    thumbnail: '03_penedo_da_saudade_2024_thumbnail.png'},
  ]},
  {name: "Afetos / Desafetos",
  folder: "fotos/6_afetos_desafetos",
  roomImage: "fotos/imagens_titulos/AFETOS_DESAFETOS.png",
  pages: [
    {images: [
      {image: "78_afetos_desafetos_orquestra_sinfonica_2018.png",
      title: "Orquestra Sinfónica",
      year: "2018",
      workDetails: "560 x 430 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-01-orquestra-sinfonica.png"},
    {images: [
      {image: "79_afetos_desafetos_jazz_2018.png",
      title: "Jazz",
      year: "2018",
      workDetails: "560 x 430 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-02-jazz.png"},
    {images: [
      {image: "80_afetos_desafetos_afetos_rock_2018.png",
      title: "Afetos Rock",
      year: "2018",
      workDetails: "700 x 500 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-03-afetos-rock.png"},
    {images: [
      {image: "81_afetos_desafetos_u2_2018.png",
      title: "U2",
      year: "2018",
      workDetails: "500 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-04-u2.png"},
    {images: [
      {image: "82_afetos_desafetos_encontro_com_desiderio_2018.png",
      title: "Encontro com Desidério",
      year: "2018",
      workDetails: "500 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-05-encontro-com-desiderio.png"},
    {images: [
      {image: "83_afetos_desafetos_noite_branca_2018.png",
      title: "Noite Branca",
      year: "2018",
      workDetails: "700 x 500 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-06-noite-branca.png"},
    {images: [
      {image: "84_afetos_desafetos_noite_techno_2018.png",
      title: "Noite Techno",
      year: "2018",
      workDetails: "700 x 500 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-07-noite-techno.png"},
    {images: [
      {image: "85_afetos_desafetos_fado_2018.png",
      title: "Fado",
      year: "2018",
      workDetails: "500 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-08-fado.png"},
    {images: [
      {image: "86_afetos_desafetos_tango_2018.png",
      title: "Tango",
      year: "2018",
      workDetails: "700 x 500 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-09-tango.png"},
    {images: [
      {image: "87_afetos_desafetos_as_you_must_live_2018.png",
      title: "As You Must Live",
      year: "2018",
      workDetails: "500 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-10-as-you-must-live.png"},
    {images: [
      {image: "88_afetos_desafetos_classificados_2019.png",
      title: "Classificados",
      year: "2019",
      workDetails: "700 x 500 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-11-classificados.png"},
    {images: [
      {image: "89_afetos_desafetos_carta_pensada_para_a_minha_namorada_2019.png",
      title: "Carta Pensada Para a Minha Namorada",
      year: "2019",
      workDetails: "500 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-12-carta.png"},
    {images: [],
    text: `Afectos e desafectos começou por ser unicamente «afectos», como se de um lirismo 
meu se tratasse, como se fosse possível trabalhar apenas sobre o que nos é 
agradável e saudável. 
Começou por se focar nos sentimentos que os diversos tipos de música produziam
em mim.
Depressa me apercebi que sou mais que somente «coisas boas». Sou o resultado de
boas e más experiências; surge «desafectos».
Com formas e materiais diferentes procurei uma nova maneira de me expressar,
mas volto sempre ao mesmo ideal (construir, destruir, reconstruir).
Cruzo-me na minha necessidade de um caminho diferente, com muitas pessoas,
mas duas foram fundamentais para o iniciar. Um músico, Bruno Correia, que me
desafia a pintar, não os músicos mas a música, e Ana Luísa Cardoso. Aprendi 
com ela a percepcionar e a utilizar pequenos fragmentos do dia-a-dia. 
O meu cocktail de trabalho novo não é uma simples e muito fresca cerveja...
Bora!
Os botões e os tecidos, as pastas de papel, saxofone e as partituras são substituidos
por materiais que trago da farmácia, da rua, que resgato do lixo ou estavam em 
casa à espera de um fim perdido.
Quando agora caminho pela rua, já não vejo objectos jogados fora, lixo, encontro
muitas vidas novas para os mesmos... Recomeçaram o seu ciclo.

Agora mais animista, sempre ateu!`,
    thumbnail: "page-6-13-txt-afectos-e-desafectos-comecou.png"},
    {images: [
      {image: "90_afetos_desafetos_ponte_tavira_2019.png",
      title: "Ponte Tavira",
      year: "2019",
      workDetails: "1000  x 500 mm, Técnica Mista s/ Madeira"},
      {image: "91_afetos_desafetos_ponte_tavira_2019_pormenores.png"}
    ],
    thumbnail: "page-6-14-ponte-tavira.png"},
    {images: [
      {image: "92_afetos_desafetos_desacatos_2019.png",
      title: "Desacatos",
      year: "2019",
      workDetails: "350 x 700 mm, Técnica Mista"}
    ],
    thumbnail: "page-6-15-desacatos.png"},
    {text: `Será um silêncio sem fim
roubando a solidez 
dos nossos apetites vorazes 
sobre o outro.`,
    textAuthor: "Vítor Cardeira",
    images: [
      {image: "93_afetos_desafetos_a_virgem_e_os_voyeurs_2020.png",
      title: "A Virgem e os Voyeurs",
      year: "2020",
      workDetails: "700 x 500 mm, Óleo/Técnica Mista"}
    ],
    thumbnail: "page-6-16-a-virgem-e-os-voyeurs.png"},
    {images: [
      {image: "94_afetos_desafetos_casa_das_salinas_2020.png",
      title: "Casa das Salinas",
      year: "2020",
      workDetails: "1000 x 500 mm, Óleo/Técnica Mista"},
      {image: "95_afetos_desafetos_casa_das_salinas_2020_pormenores.png"}
    ],
    thumbnail: "page-6-17-casa-das-salinas.png"},
  ]},
  /****************************************************************************
   *
   * Y1 is hidden.
   *
   ***************************************************************************/
  /*
  {name: "Y1",
  folder: "fotos/7_y1",
  roomImage: "fotos/imagens_titulos/Y1.png",
  pages: [
    {images: [],
    text: `Uma mulher dança nas ruas desertas,
na solidão que desce dos candeeiros
lacrimejantes,
enquanto o desejo emerge das memórias
recalcadas rompendo o passado,
gangrenando a carne e estilhaçando
os ossos fossilizados.
O vento, que se apodera de tudo e tudo leva,
soprar-lhe-á na face e os seus cabelos
ensarilhados apontar-lhe-ão os campos
das serpentes caladas que te separam
do fim.
A mulher velha dança e dança e dança e
dança,
e tudo envelhece à sua volta.

Dança e viaja à velocidade do tempo que passa,
embalada no sibilar doce das mórbidas serpentes
e das performances das borboletas assassinas.
A mulher dança, dança na nudez sideral do
devir voltando, de quando em vez, à periferia
da noite que a todos envolve e a todos seduz.
Regressa e exibe, triunfal, a sua juventude
resplandecente, retorno ao pecado original
onde encontra aquele que não habita a cidade
nem nunca encontrará os caminhos que levam
ao nada – um homem devastado por um
desejo incontinente do medo.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-7-01-txt-uma-mulher-danca.png"},
    {images: [
      {image: "96_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "340 x 270 mm, Técnica Mista (Col. Privada)"}
    ],
    thumbnail: "page-7-02-sem-titulo.png"},
    {images: [
      {image: "97_y1_praia_de_de_lesbos_1996.png",
      title: "Praia de Lesbos",
      year: "1996",
      workDetails: "1000 x 810 mm, Óleo/Técnica Mista"},
      {image: "98_y1_praia_de_de_lesbos_1996_pomenores.png"}
    ],
    thumbnail: "page-7-03-praia-de-lesbos.png"},
    {images: [
      {image: "99_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "500 x 320 mm, Grafite e tinta-da-china  (Col. Privada)"}
    ],
    thumbnail: "page-7-04-sem-titulo.png"},
    {images: [
      {image: "100_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "500 x 320 mm, Grafite e tinta-da-china  (Col. Privada)"}
    ],
    thumbnail: "page-7-05-sem-titulo.png"},
    {images: [
      {image: "101_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "500 x 320 mm, Grafite e tinta-da-china  (Col. Privada)"}
    ],
    thumbnail: "page-7-06-sem-titulo.png"},
    {images: [
      {image: "102_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-07-sem-titulo.png"},
    {images: [
      {image: "103_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-08-sem-titulo.png"},
    {images: [
      {image: "104_y1_a_queda_dum_anjo_1995.png",
      title: "A Queda dum Anjo",
      year: "1995",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china  (Col. Privada)"}
    ],
    thumbnail: "page-7-09-a-queda-de-um-anjo.png"},
    {images: [
      {image: "106_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-10-sem-titulo.png"},
    {images: [
      {image: "107_y1_sem_titulo_1995.png",
      title: "Sem Título",
      year: "1995",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"}
    ],
    thumbnail: "page-7-11-sem-titulo.png"},
    {images: [
      {image: "108_y1_reencontro_1995.png",
      title: "Reencontro",
      year: "1995",
      workDetails: "650 x 500 mm, Grafite e tinta-da-china  (Col. Privada)"},
    ],
    thumbnail: "page-7-12-reencontro.png"},
    {images: [
      {image: "109_y1_casino_1996.png",
      title: "Casino",
      year: "1995",
      workDetails: "650 x 500 mm, Grafite e tinta-da-china  (Col. Privada)"},
    ],
    thumbnail: "page-7-13-casino.png"},
    {images: [
      {image: "110_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-14-sem-titulo.png"},
    {images: [
      {image: "111_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-15-sem-titulo.png"},
    {images: [
      {image: "112_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-16-sem-titulo.png"},
    {images: [
      {image: "113_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-17-sem-titulo.png"},
    {images: [
      {image: "114_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "400 x 300 mm, Grafite e tinta-da-china  (Col. Privada)"},
    ],
    thumbnail: "page-7-18-sem-titulo.png"},
    {images: [
      {image: "115_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "400 x 300 mm, Grafite e tinta-da-china  (Col. Privada)"},
    ],
    thumbnail: "page-7-19-sem-titulo.png"},
    {images: [
      {image: "116_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-20-sem-titulo.png"},
    {images: [
      {image: "117_y1_sem_titulo_1999.png",
      title: "Sem Título",
      year: "1999",
      workDetails: "320 x 500 mm, Grafite e tinta-da-china (Col. Privada)"},
    ],
    thumbnail: "page-7-21-sem-titulo.png"},
  ]},
  */
/*NOTA: não está no PDF
P.98a
Sublimação (1995)
105_y1_sublimacao_1995.png
      */
  /****************************************************************************
   *
   * Y2 is hidden.
   *
   ***************************************************************************/
  /*
  {name: "Y2",
  folder: "fotos/8_y2",
  roomImage: "fotos/imagens_titulos/Y2.png",
  pages: [
    {images: [
      {image: "118_y2_sem_titulo_2_1997.png",
      title: "Sem Título, 2",
      year: "2017",
      workDetails: "297 x 420 mm, Pastel oleoso"}
    ],
    thumbnail: "page-8-01-sem-titulo.png"},
    {images: [
      {image: "119_y2_fellatio_2016.png",
      title: "Fellatio",
      year: "2016",
      workDetails: "297 x 210 mm, Técnica mista"}
    ],
    thumbnail: "page-8-02-fellatio.png"},
    {images: [],
    text: `Sabes? As minhas mãos percorrem as tuas coxas como autómatos cegos.
Sobem ao encontro dos húmidos e escaldantes precipícios que se erguem para lá da memória,
como se guiados por fios invisíveis de desejo e vertigem.
Mergulham como pássaros enlouquecidos, como títeres vagabundos navegando na
volúpia da pele, na fragrância das carnes ocultas.
Os dedos desesperam. Ocultam-se para lá da penugem que cresce nos vales profundos do corpo.
Tudo geme e estremece.
E grita na noite sem fim.
Nos confins da memória, uma névoa difusa ergue-se envolvendo os socalcos que me foram
revelados por deuses desavindos. Não sei se vá para longe, se me enrole em ti.
O passado já não existe, se é que existiu mesmo, com a mesma claridade dos dias
em que te conheci. Não me consigo lembrar do teu sorriso de outrora. Da tua irresponsável
tristeza galopando em gargalhadas indomáveis. O nada vestindo a raiva do desejo.
O nada encantando o estertor do encontro brutal dos corpos. Camuflando o que restou
depois da tempestade.
Sabes? Se as minhas mãos respondessem aos pensamentos, poderíamos encontrar um caminho mais justo.
Mais fácil de percorrer nas noites sombrias do tempo que se anuncia.
Do tempo que se esgota quando o amor nos transporta.
As minhas mãos regressam e aquilo que resiste soçobra sedimentando na pradaria coberta de escamas purulentas.
Enfim livre, carregando a culpa de não te ter.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-8-03-txt-sabes-as-minhas-maos.png"},
    {images: [
      {image: "120_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão"},
    ],
    thumbnail: "page-8-04-sem-titulo.png"},
    {images: [
      {image: "121_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão e Colagem"}
    ],
    thumbnail: "page-8-05-sem-titulo.png"},
    {images: [
      {image: "122_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão"},
    ],
    thumbnail: "page-8-06-sem-titulo.png"},
    {images: [
      {image: "123_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão"},
    ],
    thumbnail: "page-8-07-sem-titulo.png"},
    {images: [
      {image: "124_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão e fotografia"},
    ],
    thumbnail: "page-8-08-sem-titulo.png"},
    {images: [
      {image: "125_y2_sem_titulo_2016.png",
      title: "Sem Título",
      year: "2016",
      workDetails: "297 x 210 mm, Carvão e colagem"},
    ],
    thumbnail: "page-8-09-sem-titulo.png"},
    {images: [
      {image: "126_y2_amigas_2017.png",
      title: "Amigas",
      year: "2017",
      workDetails: "297 x 210 mm, Pastel oleoso"}
    ],
    thumbnail: "page-8-10-amigas.png"},
    {images: [
      {image: "127_y2_sem_titulo_2017.png",
      title: "Sem Título",
      year: "2017",
      workDetails: "297 x 420 mm, Pastel e grafite"}
    ],
    thumbnail: "page-8-11-sem-titulo.png"},
    {images: [
      {image: "128_y2_o_meu_sofa_2017.png",
      title: "O Meu Sofá",
      year: "2017",
      workDetails: "297 x 420 mm, Pastel e grafite"}
    ],
    thumbnail: "page-8-12-o-meu-sofa.png"},
    {images: [
      {image: "129_y2_o_teu_banho_2018.png",
      title: "O Teu Banho",
      year: "2018",
      workDetails: "210 x 297 mm, Pastel e grafite"}
    ],
    thumbnail: "page-8-13-o-teu-banho.png"},
    {images: [
      {image: "130_y2_a_menina_ma_2019.png",
      title: "“A Menina Má”",
      year: "2019",
      workDetails: "210 x 297 mm, Pastel e óleo"}
    ],
    text: `O amor 
é uma fuga
sem fim.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-8-14-a-menina-ma.png"},
    {images: [
      {image: "131_y2_bukowski_2020.png",
      title: "“Bukowski”",
      year: "2020",
      workDetails: "310 x 205 mm, Pastel e óleo"}
    ],
    thumbnail: "page-8-15-bukowski.png"},
  ]},
  */
  {name: "3D",
  folder: "fotos/9_3d",
  roomImage: "fotos/imagens_titulos/3D.png",
  pages: [
    {images: [
      {image: "132_3d_adolescencia_do_atomo_1997.png",
      title: "Adolescência do Átomo",
      year: "1997",
      workDetails: "700 x 500 x 550 mm, Técnica Mista"},
      {image: "133_3d_adolescencia_do_atomo_1997_pormenores.png"}
    ],
    thumbnail: "page-9-01-adolescencia-do-atomo.png"},
    {images: [],
    text: `Artefactos

As coisas só são coisas se lhes atribuirmos um lugar no universo das coisas,
dos artefactos, dos elementos inúteis.
Sem contexto, os artefactos não sobrevivem à sua existência.
Isolados, lutam para emergir do caos: partículas ausentes limitadas à
função solitária determinada pela sua fabricação.
Quando, no devir do acaso se juntam a outras coisas errantes,
renascem como personagens livres de um teatro, livres da função,
como construtoras do significado da sua construção.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-9-02-txt-artefactos.png"},
    {images: [
      {image: "134_3d_happy_2017.png",
      title: "Happy",
      year: "2017",
      workDetails: "305 x 200 x 335 mm, Técnica Mista"},
      {image: "135_3d_happy_2017_pormenores.png"}
    ],
    thumbnail: "page-9-03-happy.png"},
    {images: [
      {image: "136_3d_malmequer_vivaz_2014.png",
      title: "Malmequer Vivaz",
      year: "2014",
      workDetails: "620 x 620 mm, Técnica Mista"}
    ],
    thumbnail: "page-9-04-malmequer-vivaz.png"},
    {images: [
      {image: "137_3d_rosa_textura_violeta_2019.png",
      title: "Rosa Textura de Violeta",
      year: "2019",
      workDetails: "630 x 560 mm, Técnica Mista"}
    ],
    thumbnail: "page-9-05-rosa-textura-de-violeta.png"},
    {images: [
      {image: "138_3d_janela_do_desassossego_1_2015.png",
      title: "Janela do Desassossego, 1",
      year: "2015",
      workDetails: "400 x 1250 x 40 mm, Técnica Mista"}
    ],
    thumbnail: "page-9-06-janela-do-desassossego.png"},
    {images: [
      {image: "139_3d_janela_do_desassossego_2_2015.png",
      title: "Janela do Desassossego, 2",
      year: "2015",
      workDetails: "430 x 1250 x 40 mm, Técnica Mista"},
      {image: "140_3d_janelas_do_desassossego_2015.png"}
    ],
    thumbnail: "page-9-07-janela-do-desassossego-2.png"},
    {images: [
      {image: "142_3d_serie_b_2018.png",
      title: "Série B",
      year: "2018",
      workDetails: "350 x 400 x 500 mm, Técnica Mista"},
      {image: "141_3d_serie_b_2018_pormenores.png"}
    ],
    thumbnail: "page-9-08-serie-b.png"},
    {images: [
      {image: "143_3d_semen_de_cera_2019.png",
      title: "Sémen de Cêra",
      year: "2019",
      workDetails: "300 x 200 x 1100 mm, Técnica Mista"},
      {image: "144_3d_semen_de_cera_2019_pormenor.png"},
      {image: "145_3d_semen_de_cera_2019_pormenor.png"},
    ],
    thumbnail: "page-9-09-semen-de-cera.png"},
    {images: [
      {image: "146_3d_o_parto_2019.png",
      title: "O Parto",
      year: "2019",
      workDetails: "230 x 200 x 410 mm, Técnica Mista"},
      {image: "147_3d_o_parto_2019_pormenor.png"},
      {image: "148_3d_o_parto_2019_pormenor.png"},
    ],
    thumbnail: "page-9-10-o-parto.png"},
    {images: [
      {image: "149_3d_celebrar_a_tua_vida_2019.png",
      title: "Celebrar a tua Vida",
      year: "2019",
      workDetails: "890 x 570 x 315 mm, Técnica Mista"},
      {image: "150_3d_celebrar_a_tua_vida_2019_pormenores.png"},
    ],
    thumbnail: "page-9-11-celebrar-a-tua-vida.png"},
    {images: [],
    text: `Se não morrêssemos,
ninguém largaria uma conversa a meio,
ninguém se levantaria da esplanada fria
sem se despedir para sempre.`,
    textAuthor: "Vítor Cardeira",
    thumbnail: "page-9-12-txt-se-nao-morressemos.png"}
  ]},
  /*TODO -> não há folder nem roomImage! a colocar na secção sobre/copyright/credits
P.137
Lembras-te quando te diziam que quando escrevias uma letra para uma música tu contavas uma história?

Sim, lembro-me...

Ao organizarmos este portefólio, não achas que contaste a tua história desde que chegaste ao Algarve e que decidiste pintar? Não te apercebeste que puseste nos teus trabalhos o teu íntimo, a tua forma de ser, os teus desejos e frustrações, quase todas as tuas vivências?

Achas que isso me incomoda!? Eu sei que as esquadrias representam o meu lado mais conservador e sei que preciso desse lado para depois me libertar e começar a criar. Isso dá-me imenso prazer!
É verdade que as pessoas com quem me vou relacionando me influenciam e estão presentes nos meus trabalhos. São fases da vida e são, simultaneamente, homenagens. Fazem-me sentir agradecido e mais completo.
Acho que ninguém se conhece na totalidade. Um pouco como o universo, vamo-nos descobrindo, vamos crescendo, querendo conhecer-nos mais e ser maiores. Um conhecimento incompleto mas crescente, como uma totalidade perfeita nesse espaço de tempo.


P.138
© José Mário Carolino Portefólio

  {name: "Perdidos & Achados",
  folder: "fotos/",
  pages: [
    {images: [
    ],},
  ]},*/
  ];

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {
  rooms: GalleryRoom[];
  imageRooms: Map<GalleryImage, GalleryRoom>;
  pageRooms: Map<GalleryPage, GalleryRoom>;
  roomsById: Map<number, GalleryRoom>;
  pagesById: Map<number, GalleryPage>;

  constructor() { 
    this.rooms = CATALOGUE;
    this.imageRooms = new Map<GalleryImage, GalleryRoom>();
    this.pageRooms = new Map<GalleryPage, GalleryRoom>();
    this.roomsById = new Map<number, GalleryRoom>();
    this.pagesById = new Map<number, GalleryPage>();
    let roomId = 1;
    let pageId = 1;
    this.rooms.forEach(room => {
      this.roomsById.set(roomId++, room);
      room.pages.forEach(page => {
        this.pagesById.set(pageId++,page);
        this.pageRooms.set(page, room);
        page.images.forEach(image => this.imageRooms.set(image, room));
      })
    });
  }
  getPage(id: number): GalleryPage {
    return this.pagesById.get(id);
  }
  getPageId(page: GalleryPage): number {
    let matchId: number;
    this.pagesById.forEach((aPage, id) => {
      if (page == aPage) {
        matchId = id;
      }
    });
    return matchId;
  }
  getPreviousPage(page: GalleryPage): GalleryPage {
    const room = this.pageRooms.get(page);
    const pageIndex = room.pages.indexOf(page);
    let previousPage = undefined;
    if (pageIndex > 0) {
      previousPage = room.pages[pageIndex - 1];
    }
    return previousPage;
  }
  getNextPage(page: GalleryPage): GalleryPage {
    const room = this.pageRooms.get(page);
    const pageIndex = room.pages.indexOf(page);
    let nextPage = undefined;
    if (pageIndex < room.pages.length - 1) {
      nextPage = room.pages[pageIndex + 1];
    }
    return nextPage;
  }
  getRoom(id: number): GalleryRoom {
    return this.roomsById.get(id);
  }
  getRoomId(room: GalleryRoom): number {
    let matchId: number;
    this.roomsById.forEach((aRoom, id) => {
      if (room == aRoom) {
        matchId = id;
      }
    });
    return matchId;
  }
  getRooms(): GalleryRoom[] {
    return this.rooms;
  }
  roomImgLink(room: GalleryRoom, img: string): string {
      return JMCAROLINO_BASE_URL + room.folder + "/" + img
  }
  getImageLink(image: GalleryImage): string {
    let room = this.imageRooms.get(image)
    return this.roomImgLink(room, image.image)
  }
  getRoomImageLink(roomImage: string): string {
    return JMCAROLINO_BASE_URL + roomImage;
  }
  getSecondaryImages(page: GalleryPage): GalleryImage[] {
    return page.images.filter(img => !img.title);
  }
  getMainImages(page: GalleryPage): GalleryImage[] {
    return page.images.filter(img => img.title);
  }
  getRoomThumbnails(room: GalleryRoom): Map<GalleryPage, string> {
      let thumbs = new Map<GalleryPage, string>()
      room.pages.forEach(page => {
          if (page.thumbnail) {
            thumbs.set(page, this.roomImgLink(room, page.thumbnail))
          }
      })
      return thumbs
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryRoomComponent } from './gallery-room/gallery-room.component';
import { GalleryPageComponent } from './gallery-page/gallery-page.component';
import { HomePageComponent } from './home-page/home-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent},
  { path: 'room/:id', component: GalleryRoomComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'},
  { path: 'page/:id', component: GalleryPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

import { CatalogueService } from './catalogue.service';
import { GalleryRoom } from './gallery-room';
import { Subscription } from 'rxjs';

export const APP_TITLE = 'José Mário Carolino';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = APP_TITLE;
  rooms: GalleryRoom[];
  subscription: Subscription;
  constructor (private catalogue: CatalogueService) {}
  ngOnInit(): void {
    this.rooms = this.catalogue.getRooms();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogueService } from '../catalogue.service';
import { GalleryRoom } from '../gallery-room';
import { Subscription } from 'rxjs';
import { GalleryPage } from '../gallery-page';

@Component({
  selector: 'app-gallery-room',
  templateUrl: './gallery-room.component.html',
  styleUrls: ['./gallery-room.component.css']
})
export class GalleryRoomComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private catalogue: CatalogueService
  ) {}
  room: GalleryRoom;
  hasThumbnails = false
  thumbnails: Map<GalleryPage, string>
  paramMapSubscription: Subscription;
  ngOnInit(): void {
    this.paramMapSubscription = this.route.paramMap.subscribe(() => {
      this.setRoom();
    })
    this.setRoom();
  }
  ngOnDestroy(): void {
    this.paramMapSubscription.unsubscribe();
  }
  setRoom(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.room = this.catalogue.getRoom(id);
    if (this.room) {
      this.thumbnails = this.catalogue.getRoomThumbnails(this.room)
      this.hasThumbnails = this.thumbnails.size > 0
    }
  }
}

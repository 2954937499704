<div *ngIf="page">
    <p>
        <span *ngIf="previousPage; else disabledPreviousPage">
            <a routerLink="/page/{{this.catalogue.getPageId(previousPage)}}" class="prev-next-link" title="Anterior">&laquo; Anterior</a>
        </span>
        <ng-template #disabledPreviousPage>
            <span class="prev-next-link">&laquo; Anterior</span>
        </ng-template>
        &nbsp;&nbsp;
        <span *ngIf="nextPage; else disabledNextPage">
            <a routerLink="/page/{{this.catalogue.getPageId(nextPage)}}" class="prev-next-link" title="Seguinte">Seguinte &raquo;</a>
        </span>
        <ng-template #disabledNextPage>
            <span class="prev-next-link">Seguinte &raquo;</span>
        </ng-template>
    </p>
    <h1 *ngIf="page.title" class="page-title">{{page.title}}</h1>
    <p *ngIf="page.text">
        <span *ngFor="let line of page.text.split('\n')" class="page-text">{{line}}<br/></span>
        <span *ngIf="page.textAuthor" class="page-text-author">{{page.textAuthor}}</span>
    </p>
    <ul>
        <li *ngFor="let image of mainImages">
            <a href="{{catalogue.getImageLink(image)}}">
                <img src="{{catalogue.getImageLink(image)}}" alt="{{image.title}}" class="primary-image"/>
            </a>
            <span *ngIf="image.title" class="image-title"><br/>{{image.title}}&nbsp;</span>
            <span *ngIf="image.subTitle" class="image-subtitle">{{image.subTitle}}&nbsp;</span>
            <span *ngIf="image.year" class="image-year">({{image.year}})</span>
            <span *ngIf="image.workDetails" class="image-work-details"><br/>{{image.workDetails}}</span>
        </li>
    </ul>
    <div class="row">
        <div class="column">
            <span *ngFor="let image of secondaryImages">
                <a href="{{catalogue.getImageLink(image)}}">
                    <img src="{{catalogue.getImageLink(image)}}" class="secondary-image"/>
                </a>
            </span>
        </div>
    </div>
    <p>
        <span *ngIf="previousPage; else disabledPreviousPage">
            <a routerLink="/page/{{this.catalogue.getPageId(previousPage)}}" class="prev-next-link" title="Anterior">&laquo; Anterior</a>
        </span>
        <ng-template #disabledPreviousPage>
            <span class="prev-next-link">&laquo; Anterior</span>
        </ng-template>
        &nbsp;&nbsp;
        <span *ngIf="nextPage; else disabledNextPage">
            <a routerLink="/page/{{this.catalogue.getPageId(nextPage)}}" class="prev-next-link" title="Seguinte">Seguinte &raquo;</a>
        </span>
        <ng-template #disabledNextPage>
            <span class="prev-next-link">Seguinte &raquo;</span>
        </ng-template>
    </p>
</div>

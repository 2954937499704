import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogueService } from '../catalogue.service';
import { GalleryPage } from '../gallery-page';
import { GalleryImage } from '../gallery-image';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.css']
})
export class GalleryPageComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private catalogue: CatalogueService
  ) {}
  page: GalleryPage;
  previousPage: GalleryPage;
  nextPage: GalleryPage;
  mainImages: GalleryImage[];
  secondaryImages: GalleryImage[];
  paramMapSubscription: Subscription;
  ngOnInit(): void {
    this.paramMapSubscription = this.route.paramMap.subscribe(() => {
        this.setPage();
      })
    this.setPage();
  }
  ngOnDestroy(): void {
    this.paramMapSubscription.unsubscribe();
  }
  setPage(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.page = this.catalogue.getPage(id);
    if (this.page) {
      this.mainImages = this.catalogue.getMainImages(this.page);
      this.secondaryImages = this.catalogue.getSecondaryImages(this.page);
      this.previousPage = this.catalogue.getPreviousPage(this.page);
      this.nextPage = this.catalogue.getNextPage(this.page);
    }
  }
}

<div class="sidebar">

    <div class="sidebardiv">
    <h1><a href=".">{{title}}</a></h1></div>
<div class="sidebardiv">
<ul class="index">
    <li *ngFor="let room of rooms">
        <a routerLink="/room/{{this.catalogue.getRoomId(room)}}"
        routerLinkActive="active">{{room.name}}</a>
    </li>
</ul>
</div>

<div class="sidebardiv">
<p><a href="./2021_04_18_portefolio_jose_mario_carolino.pdf">Portef&oacute;lio (PDF)</a></p>
<p>Textos: <strong>Vítor Cardeira</strong> | <strong>{{title}}</strong></p>
<p>Web Design: <strong>Luís Oliveira</strong></p>
<p>Créditos Fotográficos: <strong>Luís Paulo Ferreira</strong> | <strong>Pedro Barão</strong></p>
<p>Santa Conceição Gráfica: <strong>Pedro Barão</strong></p>
<small>&copy; 2020-2024, {{title}}</small>
<small><a href="mailto:jmcarolino.pt@gmail.pt">jmcarolino.pt&#64;gmail.pt</a></small>
</div>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
